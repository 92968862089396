<template>
  <div class="fb-document-content" v-html="content"></div>
</template>

<script>
export default {
  data () {
    return {
      content: '<span>诉讼状页面</span>'
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
